import { shallowEqual, useDispatch } from "react-redux";
import { Role } from "../../../system/roles/constants";
import { globalConfigState } from "../../ActiveBrand/constants";
import updateActiveBrand from "../../ActiveBrand/api/action";
import { setCookie } from "../../../system/helpers/cookies";
import { APP_ROUTES } from "../../../system/router/constants";
import {
  getBrandCode,
  isLoggedIn,
} from "../../../system/helpers/helpfulFunctions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { resetStorage, verifySSOToken } from "./helpers";
import { ApplicationState } from "../../../system/reducers";
import { useSelector } from "react-redux";
import setSSOToken from "./actions";
import { LS_KEYS } from "../../../system/constants/localStorage";

export const useHandleLoginSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = (data, redirect = true) => {
    setCookie("token", data.token);

    const isSuperAdmin = Role.SuperAdmin === data.profile.name;
    const hasGlobalConfigAccess = !!data.profile.permissions.filter(
      (perm: any) => perm.alias.toLowerCase().includes("system")
    ).length;

    if (!(isSuperAdmin || hasGlobalConfigAccess)) {
      const brand = data.profile.brands[0];
      dispatch(
        updateActiveBrand({
          name: brand.name,
          icon: getBrandCode(brand.name),
          color: brand.settings["color"],
          id: brand.id,
        })
      );
    } else {
      dispatch(updateActiveBrand(globalConfigState));
    }
    localStorage.setItem(
      "authData",
      JSON.stringify({
        name: data.profile.name,
        permissions: data.profile.permissions,
      })
    );

    if (redirect) {
      navigate(APP_ROUTES.dashboard);
    }
  };

  return onSuccess;
};

export const useHandleOAuthLoginSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleHUBSuccessLogin = useHandleLoginSuccess();

  const onSuccess = (data) => {
    let redirectLocation = localStorage.getItem(LS_KEYS.LOCATION);

    if (!redirectLocation) {
      return;
    } else if (redirectLocation === APP_ROUTES.oauth) {
      redirectLocation = APP_ROUTES.dashboard;
    }

    localStorage.setItem(LS_KEYS.TOKEN, data?.internal?.access_token);
    localStorage.setItem(LS_KEYS.TOKEN_EXPIRATION, data?.internal?.expires_in);
    localStorage.removeItem(LS_KEYS.CODE_VERIFIER);
    localStorage.removeItem(LS_KEYS.LOCATION);

    dispatch(setSSOToken(data?.internal?.access_token));
    handleHUBSuccessLogin(data?.external, false);
    navigate(redirectLocation);
  };
  return onSuccess;
};

export const useInitializeToken = () => {
  const dispatch = useDispatch();
  const { sso_token } = useSelector(
    (state: ApplicationState) => state.auth,
    shallowEqual
  );

  const hasHubToken = () => isLoggedIn();

  const onLocalStorageChange = (lsEvent) => {
    if (lsEvent.key === LS_KEYS.TOKEN) {
      if (!lsEvent.newValue) {
        resetStorage();
      }
      dispatch(setSSOToken(lsEvent.newValue));
    }
  };

  useEffect(() => {
    const lsToken = localStorage.getItem(LS_KEYS.TOKEN);
    if (lsToken && !sso_token) {
      dispatch(setSSOToken(lsToken));
    }

    window.addEventListener("storage", onLocalStorageChange);

    return () => window.removeEventListener("storage", onLocalStorageChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO: remove after successfull SSO Integration
    // Don't need to invoke sso token verification flow
    // If we are logged in under HUB Auth flow
    if (hasHubToken()) return;

    const lsToken = localStorage.getItem(LS_KEYS.TOKEN);
    if (!sso_token && !lsToken) {
      verifySSOToken(window.location);
    }
  }, [sso_token]);
};
